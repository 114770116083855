import React, { useContext } from "react"
import { navigate } from "gatsby"
import styled from "@emotion/styled"
import { QuoteContext } from "../../Context/QuoteContext"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import { QuoteContainer } from "../bilforsakring/lagg-till-mobilnummer"
import Icon from "../../components/Icon/Icon"
import { useTheme } from "emotion-theming"
import FormLoader from "../../components/GlobalAssets/FormLoader"
import { isNullOrWhitespace } from "../../utils/HelperFunctions"

const ChangePaymentCompletePage = ({ location }) => {
  const { state } = useContext(QuoteContext)
  const theme = useTheme()

  if (!state.hasQuote && !state.hasLoadedQuote) {
    if (typeof window === "undefined") {
      return null
    }

    // Handle refresh/history-back
    if (isNullOrWhitespace(location.search)) {
      navigate("/")
      return null
    }

    navigate(`/s?${new URLSearchParams(location.search).toString()}`)

    return null
  }

  return (
    <Layout>
      <SEO title="evoli - Betalsätt ändrat." noIndex={true} />

      <QuoteContainer>
        {state.isLoading && <FormLoader />}
        <QuoteHeroContent>
          <Icon
            name="flag"
            bgColor={theme.colors.CardContainer.iconBackground}
            iconColor={theme.colors.CardContainer.iconColor}
            iconSize="25"
          />
          <h1>Betalsätt ändrat</h1>
          {state.quote.result.productName === "evoli" ? (
            <p>
              Du har ändrat betalsätt för din{" "}
              <LowerCase>{state.quote.result.coverLevelLong}</LowerCase>{" "}
              gällande bil{" "}
              <UpperCase>{state.quote.result.registrationNumber}</UpperCase>.
            </p>
          ) : (
            <p>Du har ändrat betalsätt för din försäkring.</p>
          )}
        </QuoteHeroContent>

        <ContentContainer>
          <p>Du kan nu stänga den här sidan.</p>
        </ContentContainer>
      </QuoteContainer>
    </Layout>
  )
}

export default ChangePaymentCompletePage

const UpperCase = styled.span`
  text-transform: uppercase;
`
const LowerCase = styled.span`
  text-transform: lowercase;
`

const QuoteHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 18px;

  @media (max-width: 374px) {
    margin-top: 0px;
  }

  h1,
  p {
    max-width: 305px;
  }

  h1 {
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;
    max-width: 220px;

    span {
      display: block;
      color: #5558ea;
      margin-top: 5px;
    }
  }

  p {
    margin-top: 4px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;
    max-width: 34ch;
  }

  span {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;

    @media (max-width: 374px) {
      max-width: 170px;
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  b {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #5558ea;
    margin-bottom: 7px;
  }

  a {
    color: #01cc94;
    font-size: 13px;
    font-weight: 500;
    color: #01cc94;
    margin: 0;
    margin-bottom: 30px;

    display: flex;
    align-items: center;

    @media (max-width: 375px) {
      margin-bottom: 0;
      margin-top: 15px;
    }

    svg {
      margin-right: 5px;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    margin-top: 12px;
  }

  li {
    color: #5558ea;
    font-size: 13px;
    font-weight: 500;
    display: flex;

    align-items: center;

    span {
      margin-right: 8px;
      background: ${props => (props.darkMode ? "#5558ea" : "#c4ffe1")};
      color: ${props => (props.darkMode ? "#c4ffe1" : "#5558ea")};
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  p {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin: 0;
    max-width: 270px;
    margin-bottom: 18px;
  }

  img {
    width: 110px;
    height: 25px;
    margin: 0;
    margin-bottom: 10px;

    @media (max-width: 374px) {
      display: none;
    }
  }
`
